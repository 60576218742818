<template>
  <div class="an-sdk-403-page">
    <div class="an-sdk-403-page__content">
      <img
        src="https://imgcache.qq.com/qcloud/tcloud_dtc/static/open_source/d1e0ed98-262b-448f-8f61-20db82f98c84.svg"
        alt=""
        class="an-sdk-403-page__icon"
      >
      <h2 class="an-sdk-403-page__title">
        暂无权限
      </h2>
      <p class="an-sdk-403-page__text">
        暂无权限,如需申请请点击下方“申请权限”按钮
        <br>
        发起权限审批流程
      </p>
      <button
        class="an-sdk-btn"
        @click="goApply"
      >
        申请权限
      </button>
    </div>
  </div>
</template>

<script>
import { APPLY_ROLE_URL, APPLY_TOOL_URL } from '../constants/index';
export default {
  name: 'NoPermissionPage',
  props: {
    type: {
      type: String,
      validator(value) {
        return ['tool', 'role'].includes(value);
      },
      default: '',
    },
    open: {
      type: Boolean,
      default: false,
    },
    applyUrl: {
      type: String,
      default: '',
    },
  },
  computed: {
    permissionType() {
      if (this.type) {
        return this.type;
      }
      return window.location.hostname.includes('andon.') ? 'role' : 'tool';
    },
    applyLink() {
      if (this.applyUrl) return this.applyUrl;
      if (this.permissionType === 'tool') {
        return APPLY_TOOL_URL;
      }
      return APPLY_ROLE_URL;
    },
  },
  methods: {
    goApply() {
      if (this.open) {
        window.open(this.applyLink);
      } else {
        window.location.href = this.applyLink;
      }
    },
  },
};
</script>

