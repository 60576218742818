<template>
  <button
    class="an-sdk-btn"
    type="button"
    @click="onClick"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'AnButton',
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>

