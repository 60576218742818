import '../styles/index.less';
import NoPermissionPage from './page';
import NoPermissionModal from './modal';

NoPermissionPage.install = function (Vue) {
  Vue.component(NoPermissionPage.name, NoPermissionPage);
};

function noPermission({
  type = 'role',
  cb,
  selector,
  Vue = window.Vue,
  open = false,
  title,
  description,
  applyUrl,
} = {}) {
  if (document.getElementById('an-sdk-modal')) {
    return;
  }
  const Modal = Vue.extend(NoPermissionModal);
  const propsData = { type, open, applyUrl };
  if (title) {
    propsData.title = title;
  }
  if (description) {
    propsData.description = description;
  }
  const instance = new Modal({ propsData }).$mount();
  instance.$on('close', apply => cb && cb(apply));
  if (selector) {
    document.getElementById(selector).appendChild(instance.$el);
  } else {
    document.body.appendChild(instance.$el);
  }
}

export default {
  NoPermissionPage,
  noPermission,
};


