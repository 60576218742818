<template>
  <div
    id="an-sdk-modal"
    class="an-sdk-modal__overlay"
  >
    <div class="an-sdk-modal__backdrop" />
    <div
      class="an-sdk-modal__dialog"
      role="dialog"
    >
      <div class="an-sdk-modal__dialog__inner">
        <div class="an-sdk-modal__dialog__header">
          <an-button
            class="an-sdk-modal__dialog__close"
            @click="close"
          >
            <i
              class="an-sdk-icon an-sdk-icon-close"
              role="img"
              aria-label="close"
            />
          </an-button>
        </div>
        <div class="an-sdk-modal__dialog__body">
          <div class="an-sdk-media">
            <div class="an-sdk-media__left">
              <i
                class="an-sdk-icon an-sdk-icon-info"
                role="img"
                aria-label="error"
              />
            </div>
            <div class="an-sdk-media__body">
              <h3 class="an-sdk-media__title">
                {{ title }}
              </h3>
              <div class="an-sdk-media__text">
                {{ description }}
              </div>
            </div>
          </div>
        </div>
        <div class="an-sdk-modal__dialog__footer">
          <div class="an-sdk-btn__wrap">
            <an-button @click="goApply">
              申请权限
            </an-button>
            <an-button
              class="an-sdk-btn--weak"
              @click="close"
            >
              取消
            </an-button>
          </div>
        </div>
      </div>
    </div>
    <div
      tabindex="0"
      aria-hidden="true"
      style="width: 0px; height: 0px; overflow: hidden; outline: none;"
    />
  </div>
</template>

<script>
import AnButton from './button.vue';
import { APPLY_ROLE_URL, APPLY_TOOL_URL, MODAL_DESC, MODAL_TITLE } from '../constants/index';

export default {
  name: 'NoPermissionModal',
  components: {
    AnButton,
  },
  props: {
    type: {
      type: String,
      validator(value) {
        return ['tool', 'role'].includes(value);
      },
      default: '',
    },
    open: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: MODAL_TITLE,
    },
    description: {
      type: String,
      default: MODAL_DESC,
    },
    applyUrl: {
      type: String,
      default: '',
    },
  },
  computed: {
    permissionType() {
      if (this.type) {
        return this.type;
      }
      return window.location.hostname.includes('andon.') ? 'role' : 'tool';
    },
    applyLink() {
      if (this.applyUrl) return this.applyUrl;
      if (this.permissionType === 'tool') {
        return APPLY_TOOL_URL;
      }
      return APPLY_ROLE_URL;
    },
  },
  methods: {
    goApply() {
      this.close(true);
      if (this.open) {
        window.open(this.applyLink);
      } else {
        window.location.href = this.applyLink;
      }
    },
    close(apply = false) {
      this.$emit('close', apply);
      this.$el.parentNode.removeChild(this.$el);
    },
  },
};
</script>

